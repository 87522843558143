import { useState } from "react";
import { useAppContext } from "fusion-context";
import { theme } from "@washingtonpost/wpds-ui-kit";
import PropTypes from "prop-types";
import getEnv from "../../../utilities/env";

const typographyClass = {
  small: "lh-initial font--meta-text font-xxs",
  medium: "lh-initial font--headline bold font-sm",
  large: "lh-initial font--headline bold font-md"
};

const textCtaFontClass = {
  small: "font-xxs",
  medium: "font-xs",
  large: "font-sm"
};

const SubsMainBody = ({
  subsSize,
  subsHeadline,
  subsCtaText,
  subsIsTextCta,
  subsTriggerText,
  btnColor,
  btnHoverColor,
  subsMobileImg,
  subsPricing,
  subsSubscriptionLocation,
  subsContentType,
  subsCtaUrl,
  isCancelledOffer
}) => {
  const [isHover, setIsHover] = useState(false);
  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);

  const {
    siteProperties: {
      account: { acquisitionUrl, subscribeUrl }
    }
  } = useAppContext();
  const lPricing = !subsPricing ? "" : `&p=${subsPricing}`;
  const lLocation = !subsSubscriptionLocation
    ? ""
    : `&s_l=${subsSubscriptionLocation}`;
  const lContentType = !subsContentType ? "" : `&s_ct=${subsContentType}`;
  const baseUrl = isCancelledOffer
    ? `${subscribeUrl[getEnv()]}/my-post/reactivate`
    : `${subsCtaUrl || acquisitionUrl[getEnv()]}`;
  const promoHref = `${baseUrl}/?acqEntType=mktg_onsite_homepage${lPricing}${lLocation}${lContentType}`;

  const ctaButtonClass = {
    border: 0,
    borderRadius: "30px",
    padding: "8px 32px",
    maxWidth: "500px",
    fontSize: "14px",
    color: theme.colors.onCta,
    backgroundColor: isHover ? btnHoverColor : btnColor
  };

  const ctaTextClass = { color: btnColor };

  return (
    <div
      className={`center w-100 w-auto-ns pt-xl-ns pb-xl z-1 ${
        subsMobileImg ? "pt-xxs" : "pt-xl"
      }`}
    >
      <div
        data-qa={"inline-subs-headline"}
        className={`pl-sm pr-sm ${typographyClass[subsSize]} ${
          subsIsTextCta ? "dib" : "db"
        }`}
      >
        {subsHeadline}
      </div>
      <a
        href={promoHref}
        className={`db bold dib-ns ${
          subsIsTextCta
            ? `pt-0-ns pt-xs pr-sm link-hover-underline ${textCtaFontClass[subsSize]}`
            : "mt-sm btn mr-auto ml-auto btn"
        }`}
        style={subsIsTextCta ? ctaTextClass : ctaButtonClass}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-qa={`inline-subs-cta-${subsIsTextCta ? "text" : "btn"}`}
      >
        {subsCtaText}
      </a>
      {subsTriggerText && !subsIsTextCta && (
        <div className="db mt-xs font-xxxxs gray-dark">{subsTriggerText}</div>
      )}
    </div>
  );
};

SubsMainBody.propTypes = {
  subsSize: PropTypes.string,
  subsHeadline: PropTypes.string,
  subsCtaText: PropTypes.string,
  subsIsTextCta: PropTypes.bool,
  subsTriggerText: PropTypes.string,
  btnColor: PropTypes.string,
  btnHoverColor: PropTypes.string,
  subsMobileImg: PropTypes.string,
  subsPricing: PropTypes.string,
  subsSubscriptionLocation: PropTypes.string,
  subsContentType: PropTypes.string,
  subsCtaUrl: PropTypes.string,
  isCancelledOffer: PropTypes.bool
};

export default SubsMainBody;
