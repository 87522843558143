import PropTypes from "prop-types";
import { useAppContext } from "fusion-context";
import getResizedUrl from "../../../core/elements/image/resize-url";
import Image from "../../../core/elements/image/element_image";
import Lazy from "~/shared-components/WapoLazyChild";

const SubsImage = ({ subsImgUrl, width, height, containerClass }) => {
  const { siteProperties } = useAppContext();
  return (
    <Lazy eager={siteProperties.disableLazyLoading}>
      <Image
        className={containerClass}
        imgClassName="mw-100 of-cover"
        url={getResizedUrl(subsImgUrl, { w: width })}
        width={width}
        height={height}
        ImgTag="img"
        layout=""
      />
    </Lazy>
  );
};

SubsImage.propTypes = {
  subsImgUrl: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  containerClass: PropTypes.string
};

export default SubsImage;
